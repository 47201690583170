import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.mjs';
import { InlineAgent } from '../inlineAgent/InlineAgent';

const container = document.getElementById('eloquent-home') as HTMLElement;

function EloquentHome() {
  const fullConfig = resolveConfig(tailwindConfig);
  const breakpoint = fullConfig.theme.screens.lg;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [suggestion, setSuggestion] = useState('');
  const [isMobile, setIsMobile] = useState(false); // using Tailwind 'lg' breakpoint (smaller than 1024px)

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${breakpoint})`);
    const handleMediaQueryChange = (event: MediaQueryListEvent) =>
      setIsMobile(event.matches);

    // Set initial value
    setIsMobile(mediaQuery.matches);

    // Add listener
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Cleanup listener on unmount
    return () =>
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
  }, []);

  return (
    <>
      <InlineAgent
        popup={false}
        isOpen={true}
        setOpen={() => {}}
        setSuggestion={
          isMobile ? (s) => (setIsPopupOpen(true), setSuggestion(s)) : undefined
        }
        onInputClick={isMobile ? () => setIsPopupOpen(true) : undefined}
      />
      {isPopupOpen && (
        <EloquentPopup
          setOpen={setIsPopupOpen}
          setSuggestion={setSuggestion}
          suggestion={suggestion}
        />
      )}
    </>
  );
}

function EloquentPopup({
  setOpen,
  setSuggestion,
  suggestion,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuggestion: React.Dispatch<React.SetStateAction<string>>;
  suggestion: string;
}) {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-[999] flex h-full w-dvw overflow-hidden bg-typography-black/50 p-6">
      <InlineAgent
        popup={true}
        isOpen={true}
        setOpen={setOpen}
        suggestion={suggestion}
        setSuggestion={setSuggestion}
      />
    </div>,
    document.body
  );
}

const root = createRoot(container);
root.render(<EloquentHome />);
